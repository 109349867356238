import React, { useMemo } from "react";
import StickyBox from "react-sticky-box";
import classnames from "classnames";
import Heading, { Container } from "@/components/Heading";
import AvailableSizes, { Size as SizeType } from "@/components/AvailableSizes";
import MicroCopy from "@/components/MicroCopy";
import ScrollAndFixed from "@/components/ScrollAndFixed";
import ZoomIn from "@/components/ZoomIn";
import Button from "@/components/Button";
import ScrollToSee from "@/components/ScrollToSee";
import { Link, navigate } from "gatsby";
import { StoreContext } from "../StoreContext";

export type Size = SizeType;
export interface Props {
  title: React.ReactNode;
  landscapeImage?: React.ReactNode;
  images: React.ReactNode[];
  description: React.ReactNode;
  sizes?: Size[];
  price?: React.ReactNode;
  attributes?: Record<string, string>;
  availableColours?: Record<string, { link?: string; code: string }>;
  variantId?: string;
  inventoryQuantity: number;
}

type ImageWrapperProps = {
  children: React.ReactNode;
  isActive?: boolean;
};

const ImagesWrapper = ({ children }: ImageWrapperProps) => {
  return (
    <div className="flex flex-col justify-between group space-y-2">
      {children}
      <ScrollToSee text="Scroll to see more photos" />
    </div>
  );
};

const Attributes = ({ attributes, className }: Pick<Props, "attributes"> & { className?: string }) => {
  if (!attributes) {
    return null;
  }

  return (
    <dl
      className={classnames(className, "grid gap-x-1 gap-y-2 text-cod-gray")}
      style={{
        gridTemplateColumns: "auto 1fr",
      }}
    >
      {Object.entries(attributes).map(([key, value]) => {
        if (!key || !value) {
          return null;
        }

        return (
          <React.Fragment key={key}>
            <dt className="py-2 pr-20 border-t col-start-1">{key}</dt>
            <dd className="py-2 border-t col-start-2">{value}</dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
};

const AvailableColours = ({
  availableColours = {},
  className,
}: Pick<Props, "availableColours"> & { className?: string }) => {
  const colours = useMemo(() => {
    return Object.entries(availableColours);
  }, [availableColours]);

  if (!colours.length) {
    return null;
  }

  return (
    <div className={className}>
      <Heading level={5} className="text-cod-gray">
        <MicroCopy path="product.colors.title">Also available in:</MicroCopy>
      </Heading>
      <div className="flex items-center">
        {colours.map(([name, { code, link }]) => {
          const Component = link ? Link : "div";
          const linkProps = link ? { to: link } : {};
          return (
            // @ts-expect-error This will go away when these become actual link.
            <Component
              key={name}
              {...linkProps}
              className={classnames("w-8 h-10 mr-4 link link--inline border bg-clip-content p-0", {})}
              style={{ backgroundColor: code }}
            >
              <span className="absolute w-0 h-0 overflow-hidden">{name}</span>
            </Component>
          );
        })}
      </div>
    </div>
  );
};

const ProductDetails = ({
  title,
  landscapeImage,
  images,
  description,
  price,
  sizes,
  attributes,
  availableColours,
  variantId,
  inventoryQuantity,
}: Props) => {
  const { addVariantToCart, open } = React.useContext(StoreContext);
  const productImages = useMemo(
    () =>
      React.Children.map(images, child => {
        if (!React.isValidElement(child)) {
          return child;
        }

        return (
          <ZoomIn className="block">
            {zoom => (
              <div
                // @note: This solves an image issue. There are no tailwind class
                // for this.
                style={{ lineHeight: 0 }}
              >
                {React.cloneElement(child, {
                  className: classnames("min-h-screen", "w-full", {
                    "h-screen": !zoom,
                    "overflow-auto": zoom,
                  }),
                  objectFit: zoom ? "contain" : "cover",
                })}
              </div>
            )}
          </ZoomIn>
        );
      }),
    [images]
  );

  const isAvailableForSelling = price && addVariantToCart && variantId && inventoryQuantity > 0;

  return (
    <Container>
      <div className="flex flex-col items-start md:flex-row">
        <StickyBox offsetTop={0} className="z-10 flex-1 sticky-box">
          <div className="min-h-screen px-12 pt-40 pb-32 bg-white reveal">
            <Heading className="text-cod-gray">{title}</Heading>
            <div className="mt-16 md:w-2/3">{description}</div>
            <Attributes attributes={attributes} className="mt-12 md:w-2/3" />
            <div className="mt-20 grid md:grid-cols-2 gap-10">
              <div className="col-span-1">
                <AvailableSizes sizes={sizes} layout="select" />
              </div>
              <div className="flex items-end col-span-1">
                <Button
                  rank="secondary"
                  onClick={async () => {
                    if (!(price && addVariantToCart && variantId && inventoryQuantity > 0)) {
                      await navigate("/contact-us");

                      return;
                    }

                    await addVariantToCart(variantId, "1");

                    open();
                  }}
                >
                  {isAvailableForSelling ? (
                    <>
                      <MicroCopy path="product.add-to-basket">Add to the basket for</MicroCopy> &euro; {price}
                    </>
                  ) : (
                    <>
                      <MicroCopy path="product.not-available">Pre-order now</MicroCopy>
                      {price && ` for €  ${price}`}
                    </>
                  )}
                </Button>
              </div>
            </div>
            {availableColours && Object.keys(availableColours).length > 1 && (
              <AvailableColours availableColours={availableColours} className="mt-12" />
            )}
          </div>
        </StickyBox>
        <div className="flex flex-col flex-1">
          <ImagesWrapper>{productImages}</ImagesWrapper>
        </div>
      </div>
      {landscapeImage && <ScrollAndFixed className="w-full h-screen">{landscapeImage}</ScrollAndFixed>}
    </Container>
  );
};

export default ProductDetails;
