import React, { useContext } from "react";
import { ParallaxContext } from "../Layout";
import classnames from "classnames";
import MicroCopy from "@/components/MicroCopy";
import ChevronDown from "@/icons/chevron-down.svg";

interface Props {
  children?: React.ReactNode;
  text: string;
}

const ScrollToSee = ({ children, text }: Props) => {
  const value = useContext(ParallaxContext);
  const isVisible = value < 0.2;

  return (
    <div className={classnames({ group: Boolean(children) })}>
      {children}
      <div
        className={classnames(
          "fixed pointer-events-none flex flex-col items-center z-20 space-y-4 left-3/4 bottom-4 duration-200 transform -translate-x-1/2 opacity-0",
          {
            "group-hover:opacity-100": isVisible,
          }
        )}
      >
        <span className="hidden px-4 py-2 text-2xl font-title bg-athens-gray text-cod-gray md:block">
          <MicroCopy path="shop.scroll">{text}</MicroCopy>
        </span>
        <ChevronDown className="text-athens-gray animate-bounce" style={{ width: 60, height: 60 }} />
      </div>
    </div>
  );
};

export default ScrollToSee;
