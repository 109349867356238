import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import Cockpit from "@/components/Cockpit";
export interface Props {
  children: React.ReactNode | ((zoom: boolean) => React.ReactNode);
  className?: string;
  style?: CSSProperties;
}

const ZoomIn = ({ children, className, style }: Props) => {
  const [zoom, set] = useState(false);
  const WrapperComponent = useMemo(() => {
    if (zoom) {
      return ({ children, ...props }: React.HTMLProps<HTMLDivElement>) => (
        <div {...props}>
          <Cockpit
            onCloseClick={() => {
              set(false);
            }}
          />
          {children}
        </div>
      );
    }

    return (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
      <button
        onClick={() => {
          set(true);
        }}
        {...props}
      />
    );
  }, [zoom]);
  const handleEscapeKeyDown = useCallback(({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      set(false);
    }
  }, []);

  useEffect(() => {
    if (zoom) {
      document.body.classList.add("overflow-hidden");

      window.addEventListener("keydown", handleEscapeKeyDown);
    }

    if (!zoom) {
      document.body.classList.remove("overflow-hidden");
      window.removeEventListener("keydown", handleEscapeKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyDown);
    };
  }, [zoom, handleEscapeKeyDown]);
  const clone = useMemo(() => {
    return React.Children.map(typeof children === "function" ? children(false) : children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      return React.cloneElement(child);
    });
  }, [children]);

  return (
    <>
      <WrapperComponent
        className={classnames(className, "bg-athens-gray", {
          "fixed top-0 left-0 right-0 bottom-0 z-40 overflow-scroll transition-all": zoom,
        })}
        style={style}
      >
        {typeof children === "function" ? children(zoom) : children}
      </WrapperComponent>
      {zoom && clone}
    </>
  );
};

export default ZoomIn;
