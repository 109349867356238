import React, { useMemo } from "react";
import { graphql } from "gatsby";
import keyBy from "lodash/keyBy";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Footer, { LinkItem } from "@/components/Footer";
import ProductDetails, { Size } from "@/components/ProductDetails";
import ProductOverview from "@/components/ProductOverview";
import Media from "@/components/Media";
import MicroCopy from "@/components/MicroCopy";
import capitalize from "lodash/capitalize";
import { ProductPageQuery } from "../../graphql-types";

interface Props {
  data: ProductPageQuery;
}

const mapColours = (colors: Size[]): Record<string, { code: string; link?: string }> => {
  return colors.reduce((carry, { name, link }) => {
    return {
      ...carry,
      [name]: {
        code: name,
        link,
      },
    };
  }, {});
};

const ProductPage = ({ data }: Props) => {
  // @note: product is the variant.
  const { relatedProduct, ...product } = Object.assign({}, data.product, data.product?.product);
  const { headerLinks, footerLinks } = data.global || {};

  // @todo: related product to be figured out.
  // @todo: get images.
  const attributes: Record<string, string> = useMemo(() => {
    return ["Size", "Color", "Taglia"].reduce((carry, attribute) => {
      const option = product.selectedOptions?.find(option => option?.name === attribute)?.value;

      if (!option) {
        return carry;
      }

      return {
        ...carry,
        [attribute]: option,
      };
    }, {});
  }, [product]);
  // @todo: This is horrible, has to be done more efficently.
  const { sizes, colors } = useMemo(() => {
    if (!product.variants) {
      return { sizes: [], colors: [] };
    }

    const colors = product.variants.reduce<Size[]>((carry, variant) => {
      if (!variant?.selectedOptions) {
        return carry;
      }

      const variantOptions = keyBy(variant.selectedOptions, "name");
      const variantSize = variantOptions["Size"] || variantOptions["Taglia"];
      const currentSize = attributes["Size"] || attributes["Taglia"];

      // @note: difference between size and color is that color we don't
      // display active one.
      if (variantSize !== currentSize || variantOptions["Color"]?.value === attributes["Color"]) {
        return carry;
      }

      return carry.concat({
        name: variantOptions["Color"]?.value || "N/D",
        link: variant.slug || undefined,
      });
    }, []);

    const sizes = product.variants.reduce<Size[]>((carry, variant) => {
      if (!variant?.selectedOptions) {
        return carry;
      }

      const variantOptions = keyBy(variant.selectedOptions, "name");

      if (variantOptions["Color"]?.value !== attributes["Color"]) {
        return carry;
      }

      const variantSize = variantOptions["Size"] || variantOptions["Taglia"];
      const currentSize = attributes["Size"] || attributes["Taglia"];
      const isActive = variantSize?.value === currentSize;

      return carry.concat({
        name: variantSize?.value || "N/D",
        isActive,
        link: (!isActive && variant.slug) || undefined,
      });
    }, []);

    return {
      sizes,
      colors,
    };
  }, [attributes, product]);
  const productAttributes = useMemo(() => {
    if (!product?.attributes) {
      return {};
    }

    const capitalizedAttributes = product.attributes.map(attribute => {
      if (!attribute?.key || !attribute?.value) {
        return;
      }

      const key = capitalize(attribute.key.replace(/_/g, " "));
      return {
        [key]: attribute.value,
      };
    });

    return Object.assign({}, ...capitalizedAttributes);
  }, [product]);

  const images = useMemo(
    () =>
      [product.image, ...(product.images || [])].map(
        (image: { gatsbyImageData: IGatsbyImageData; altText?: string | null } | null | undefined, index) => {
          if (!image) {
            return null;
          }

          const imageSource = image.gatsbyImageData;

          if (!imageSource) {
            return null;
          }

          return <GatsbyImage key={index} image={imageSource} alt={image.altText || product.title || "dress image"} />;
        }
      ),
    [product]
  );
  const productVideo = null;

  /*product.media.video && (
    <ReactPlayer src={product.media.video.publicURL} width="100%" height="100%" muted playing loop />
  );*/

  return (
    <Layout header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}>
      <Seo title={`Product | ${product.title}`} description={product.shortDescription || undefined} />
      <ProductDetails
        title={product.title}
        images={[productVideo, ...images]}
        sizes={sizes}
        price={product.price}
        description={product.description}
        attributes={{ Color: attributes["Color"], ...productAttributes }}
        availableColours={mapColours(colors)}
        variantId={product.storefrontId || undefined}
        inventoryQuantity={product.inventoryQuantity || 0}
      />
      {relatedProduct && (
        <Layout.Section theme="alabaster">
          <Media
            image={
              <GatsbyImage
                image={relatedProduct.images?.[0]?.gatsbyImageData}
                alt={relatedProduct.images?.[0]?.altText || relatedProduct.title || "related product image"}
              />
            }
          >
            <ProductOverview
              title={relatedProduct.title}
              subtitle={<MicroCopy path="product.related.subtitle">You might also like</MicroCopy>}
              description={relatedProduct.shortDescription}
              link={relatedProduct?.variants?.[0]?.slug || "/"}
              image={
                <GatsbyImage
                  image={relatedProduct.images?.[1]?.gatsbyImageData}
                  alt={relatedProduct.images?.[1]?.altText || relatedProduct.title || "related product image"}
                />
              }
              isOutOfStock={
                !relatedProduct?.variants?.some(variant => variant?.inventoryQuantity && variant.inventoryQuantity > 0)
              }
            />
          </Media>
        </Layout.Section>
      )}
      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query ProductPage($id: String!) {
    product: shopifyProductVariant(id: { eq: $id }) {
      ...ProductVariant
    }
    global: dataJson {
      ...Global
    }
  }
`;
