import React, { useMemo } from "react";
import classnames from "classnames";
import Heading from "@/components/Heading";
import MicroCopy from "@/components/MicroCopy";
import { Link } from "gatsby";

export const allSizes = ["38", "40", "42", "44", "46"] as const;

export type Size = {
  name: string; // typeof allSizes[number];
  isActive?: boolean;
  link?: string;
};

type Layout = "view" | "select";
export interface Props {
  sizes?: Size[];
  className?: string;
  layout?: Layout;
  onChange?: (value: any) => void;
}

const wrapperClasses = "-ml-3 flex flex-row justify-start space-x-3";

const ViewSizes = ({ sizes = [] }: Pick<Props, "sizes">) => {
  return (
    <ul className={wrapperClasses}>
      {allSizes.map(size => {
        const includesSize = sizes.some(({ name }) => name === size);

        return (
          <li
            key={size}
            className={classnames("uppercase font-subtitile text-xs", {
              "text-black": includesSize,
              "text-silver": !includesSize,
            })}
          >
            {size}
          </li>
        );
      })}
    </ul>
  );
};

const DisabledButton = ({ className, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <button disabled className={classnames("cursor-default", className)} {...props} />
);

const SelectSizes = ({ sizes = [] }: Pick<Props, "sizes" | "onChange">) => {
  const sizeNodes = useMemo(() => {
    return allSizes.map((size, index) => {
      const id = `select-size-${index}-${Math.random()}`;
      const includesSize = sizes.find(({ name }) => name === size);
      const Component: React.ElementType = includesSize?.link ? Link : DisabledButton;
      const props = includesSize?.link ? { to: includesSize.link } : {};

      return (
        <React.Fragment key={id}>
          <Component
            {...props}
            className={classnames(
              "m-0 uppercase flex justify-center items-center rounded-full w-8 h-8 bg-alabaster font-subtitile text-sm",
              {
                "text-black": includesSize,
                "text-silver": !includesSize,
                "bg-cod-gray text-white cursor-default": includesSize?.isActive,
              }
            )}
          >
            <span className="sr-only">
              <MicroCopy path="product.size">Size</MicroCopy>
            </span>
            {size}
          </Component>
        </React.Fragment>
      );
    });
  }, [sizes]);

  return <fieldset className={wrapperClasses}>{sizeNodes}</fieldset>;
};

const layoutToComponentMap: Record<Layout, React.ElementType> = {
  view: ViewSizes,
  select: SelectSizes,
};

const AvailableSizes = ({ sizes = [], className, layout = "view", onChange }: Props) => {
  const SizesComponent = layoutToComponentMap[layout];

  return (
    <div className={className}>
      <Heading level={5} className="block mb-2.5 text-cod-gray">
        <MicroCopy path="product.sizes.title">Select a size:</MicroCopy>
      </Heading>
      <SizesComponent sizes={sizes} onChange={onChange} />
      {sizes.length === 0 && (
        <span className="block text-xs text-silver mt-2.5">
          <MicroCopy path="product.sizes.not-available">No sizes available</MicroCopy>
        </span>
      )}
    </div>
  );
};

export default AvailableSizes;
